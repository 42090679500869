import moment from "moment";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useState } from "react";
import { Consult, PaginatedConsultList } from "../../../types/consult-api";

export function DaySchedule(props: { consults: PaginatedConsultList | null }) {
    const [loading, setLoading] = useState(true);
    const today = moment().startOf("day");

    useEffect(() => {
        if (props.consults !== null) {
            setLoading(false);
        }
    }, [props.consults]);

    let filteredConsults: Consult[] = [];
    if (props.consults?.results) {
        filteredConsults = props.consults.results.filter(
            (consult) => consult.isScheduled && today.isSame(moment.utc(consult.appointmentScheduledStartsAt), "day")
        );
    }

    const formatInteractionMode = (interactionMode: string): string => {
        // Replace underscores with spaces and capitalize each word
        return interactionMode.replace(/_/g, " ").replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    };

    const formatTime = (timestamp: string | null) => {
        if (!timestamp) {
            return;
        }
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true });
    };

    const isPastEvent = (timestamp: string | null) => {
        if (!timestamp) {
            return false; // Consider it not in the past if the timestamp is not available
        }
        const now = moment();
        const scheduledTime = moment(timestamp);
        return scheduledTime.isBefore(now);
    };

    const ScheduleItem = ({ consult }: { consult: Consult }) => {
        const partnerName = consult.program.partnerName;
        const programName = consult.program.name;
        const type = formatInteractionMode(consult.interactionMode);
        const date = formatTime(consult.appointmentScheduledStartsAt);
        const pastEvent = isPastEvent(consult.appointmentScheduledStartsAt);
        const consultGuid = consult.guid;

        return (
            <>
                <Tooltip target=".calendar-event" />
                <div
                    className="text-sm calendar-event"
                    data-pr-tooltip={`${date} - ${partnerName} ⋅ ${programName} - ${type}`}
                    data-pr-position="top"
                    style={{
                        display: "flex",
                        marginTop: "8px",
                        maxWidth: "350px",
                        whiteSpace: "nowrap",
                        color: pastEvent ? "#F44949" : "#26aebc",
                        cursor: "pointer",
                        overflow: "hidden",
                    }}
                    onClick={() => {
                        window.location.assign(`/consult/${consultGuid}`);
                    }}
                >
                    <div
                        style={{
                            flexShrink: 0,
                        }}
                    >
                        <span className={"font-semibold"}>{date}</span>{" "}
                    </div>
                    <div
                        style={{
                            flexShrink: 0,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        &nbsp;
                        {"- "}
                        {partnerName} ⋅ {programName}
                    </div>
                    <div
                        style={{
                            flexShrink: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        &nbsp;
                        {"- "}
                        {type}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="todays-schedule">
            <div className="text-sm font-semibold">
                <span title={"todays-schedule"}>Today's Schedule</span>
            </div>
            {loading ? (
                <div className="text-sm" style={{ marginTop: "8px" }}>
                    Loading...
                </div>
            ) : filteredConsults.length > 0 ? (
                filteredConsults.map((consult) => <ScheduleItem key={consult.guid} consult={consult} />)
            ) : (
                <div className="text-sm" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginTop: "8px", color: "#969696" }}>
                    No scheduled consults
                </div>
            )}
        </div>
    );
}
